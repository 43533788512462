import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted,onActivated, getCurrentInstance,nextTick} from 'vue';
import CxUtil ,{ICxDataObj} from './cxUtil';
import {useRoute} from "vue-router";
import ViewFileUtil from "@/views/project/tools/manual/client/utils/viewFileUtil";
export default defineComponent ({
    name: 'clientCx',
    beforeRouteEnter(to:any,from:any,next:any){
        next((curProxyInst:any)=>{
            document.title = '车辆使用手册';
        })
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let route = useRoute();
        let dataObj:ICxDataObj=reactive<ICxDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            otherParams:{
                brandId:'',
                cxData:[]
            }
        })
        onActivated(()=>{
            buildRoutParams();
            nextTick(async()=>{//不能放到onMounted，否则只会请求一次，返回在进入就不会再请求了
                await dataObj.utilInst.initPage();
            })
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new CxUtil(proxy,dataObj);
            buildRoutParams();
        })
        //获取路由的参数
        const buildRoutParams=()=>{
            dataObj.otherParams.brandId=route.query.brandId as string;
        }
        onMounted(()=>{

        })
        //去手册页面
        const toManualPage=(cxId:string)=>{
            proxy.$router.push({path:'/clientUseManualList',query:{cxId:cxId,brandId:dataObj.otherParams.brandId}});
        }
        //返回
        const backHandler=()=>{
            proxy.$router.push({path:'/clientUseManual'});
        }
        return{
            ...toRefs(dataObj),toManualPage,backHandler
        }
    }
});